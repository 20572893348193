import { ButtonPrimary } from '@components/Button';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import React from 'react';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found-page">
      <div className="container">
        <div className="btn-back">
          <img
            className="icon-arrow"
            src="/icons/icon-left-arrow-outline.svg"
            alt="Right Arrow"
          />
          <Link href="/">
            <a>{t('common.txtGoBack')}</a>
          </Link>
        </div>
        <div className="contain">
          <div className="contain__image">
            <img src="/images/404.png" alt="Page not found" />
            <div className="title">
              <div className="title__oops">{t('common.txtOops')}</div>
              <div
                className="title__not-found"
                dangerouslySetInnerHTML={{
                  __html: t('common.txtPageNotFound'),
                }}
              />
            </div>
          </div>
          <div className="description">{t('common.txtNotFound')}</div>
          <div className="go-home">
            <Link href="/">
              <a>
                <ButtonPrimary>{t('common.txtGoToHome')}</ButtonPrimary>
              </a>
            </Link>
          </div>
          <div className="go-contact">
            <Link href="/contact">
              <a>{t('common.buttonContactUs')}</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }: any) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};

export default ErrorPage;
